'use client';
import React, { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useShallow } from 'zustand/react/shallow';
import {
  AmplitudeEvents,
  AmplitudeProperties,
} from 'whoop-analytics/lib/types';
import type { CartProduct, MembershipProduct, PromoCode } from 'ui/types';
import { userDeviceOS } from 'ui/utils/userDeviceHelpers';
import { CartContents } from 'ui/components';
import Drawer from 'ui/components/Drawer/drawer';
import { useTranslation } from '@whoop/i18n/lang/client';
import { getRegionalBatteryLimit } from '@whoop/i18n/utils/i18nHelpers';
import { getBNPLTotalToUse, getTotalsFromCartProducts } from 'ui';
import { getMembership } from 'ui//utils/membershipHelpers';
import clsx from 'clsx';
import CTACard from 'ui/components/CTACard/CTACard';
import {
  getBatteryCountInCart,
  getCartDisplayItems,
} from 'lib/utils/cartDisplayHelper';
import { getItemProperties } from 'lib/utils/analyticsHelper';
import { useAnalytics } from '@/hooks/analytics';
import LearnMoreModal from '@/components/LearnMoreModal/LearnMoreModal';
import { useSiteStore } from '@/store/SiteStoreProvider';
import type { CartButtonVariants } from '@/components/CartCTAButtons/CartCTAButtons';
import { CartCTAButtons } from '@/components/CartCTAButtons/CartCTAButtons';
import { JF_BNPL_V2, SKIP_MEM_SELECT } from '@/lib/constants/experiments';
import { useNavigation } from '@/hooks/navigation';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import {
  getPreferredFamilyPlanUpsellInfo,
  getPrepaidMembershipFamilyRanges,
} from '@/lib/utils/familyHelpers';
import {
  getMembershipCartProductInCart,
  getDeviceInCart,
} from 'lib/utils/cartManager';
import { OrderTotals } from '../OrderTotals/OrderTotals';
import StripeBNPLElement from '../StripeBNPLElement/StripeBNPLElement';

/**
 * @description Do not pass a onContinue function that has navigation logic into this component.
 * We need to move the cart out of every page and to the layout.
 */
export default function Cart({
  engravingUpsellAction,
  CTAButtonsVariant,
}: {
  engravingUpsellAction?: () => void;
  CTAButtonsVariant?: CartButtonVariants;
}): React.JSX.Element {
  const pathname = usePathname();
  const currentPage = useGetCurrentPage();

  const {
    currency,
    isCartOpen,
    setIsCartOpen,
    cartProducts,
    cartExceedsBatteryLimit,
    setCartExceedsBatteryLimit,
    updateProductQuantity,
    promoCode,
    promoInfo,
    promoContent,
    language,
    region,
    accessories,
    requiredProducts,
    addToCart,
    isRafGiftFlow,
    asAGift,
    initialTotals,
    setPendingOrder,
    experimentVariants,
    shippingAddress,
    country,
  } = useSiteStore(
    useShallow((state) => ({
      cartProducts: state.cartProducts,
      cartExceedsBatteryLimit: state.cartExceedsBatteryLimit,
      setCartExceedsBatteryLimit: state.setCartExceedsBatteryLimit,
      currency: state.currency,
      isCartOpen: state.isCartOpen,
      setIsCartOpen: state.setIsCartOpen,
      updateProductQuantity: state.updateProductQuantity,
      promoCode: state.promoCode,
      promoInfo: state.promoInfo,
      promoContent: state.promoContent,
      language: state.language,
      region: state.region,
      accessories: state.accessories,
      requiredProducts: state.requiredProducts,
      addToCart: state.addToCart,
      isRafGiftFlow: state.isRafGiftFlow,
      asAGift: state.asAGift,
      initialTotals: state.initialTotals,
      setPendingOrder: state.setPendingOrder,
      experimentVariants: state.experimentVariants,
      shippingAddress: state.shippingAddress,
      country: state.country,
    })),
  );

  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);
  const [showCartContinueButton, setShowCartContinueButton] = useState(false);
  const cartCTAButtonsVariant = accessories?.length
    ? CTAButtonsVariant ?? 'control'
    : 'continueToCheckout';

  const { t } = useTranslation('cart');
  const { trackAmplitudeEvent } = useAnalytics();

  const onCartClose = (): void => {
    setIsCartOpen(false);
  };

  const { goTo } = useNavigation();
  const shouldSkipMemSelect =
    Boolean(experimentVariants[SKIP_MEM_SELECT]) &&
    experimentVariants[SKIP_MEM_SELECT] !== 'control';

  const changeMembershipAction = (
    membershipType?: string,
    familySize?: string,
  ): void => {
    if (membershipType === 'family') {
      const label = AmplitudeProperties.FamiliyCartChangeSize;
      const family_size = familySize;
      trackAmplitudeEvent(AmplitudeEvents.ClickedFamilyMembership, {
        label,
        family_size,
      });
      goTo('family');
    } else {
      goTo('membership');
    }
    setIsCartOpen(false);
  };

  const membershipInCart: MembershipProduct | undefined = useMemo(() => {
    const membershipInCart = getMembershipCartProductInCart(cartProducts);
    if (membershipInCart) {
      return membershipInCart.item as MembershipProduct;
    }
  }, [cartProducts]);

  const showBNPLMessaging =
    (experimentVariants[JF_BNPL_V2] === 'bnpl-mtm' ||
      experimentVariants[JF_BNPL_V2] === 'bnpl-no-mtm') &&
    membershipInCart?.membership_type === 'prepaid';

  useEffect(() => {
    setPendingOrder(undefined);
  }, []);

  useEffect(() => {
    const batteryLimitForRegion = getRegionalBatteryLimit(region);
    if (!batteryLimitForRegion) {
      setCartExceedsBatteryLimit(false);
      return;
    }
    const batteryCount = getBatteryCountInCart(cartProducts, accessories ?? []);
    setCartExceedsBatteryLimit(batteryCount > batteryLimitForRegion);
  }, [cartProducts, region, accessories]);

  useEffect(() => {
    const isMembershipInCart = Boolean(membershipInCart);
    const isDeviceInCart = Boolean(getDeviceInCart(cartProducts));
    setShowCartContinueButton(
      isMembershipInCart && isDeviceInCart && !cartExceedsBatteryLimit,
    );
  }, [cartProducts, membershipInCart, cartExceedsBatteryLimit]);

  useEffect(() => {
    if (cartProducts.length !== 0) {
      if (isCartOpen) {
        document.body.style.overflow = 'hidden';
        if (userDeviceOS() === 'Windows') {
          document.body.style.paddingRight = '17px';
        }
        const { hadPrepaidMembershipsInRange } =
          getPrepaidMembershipFamilyRanges(cartItems);
        const label =
          hadPrepaidMembershipsInRange && familyMemberships
            ? AmplitudeProperties.FamilyMembershipVisible
            : undefined;
        const page = currentPage;
        trackAmplitudeEvent(AmplitudeEvents.ViewedCart, { label, page });
      } else {
        document.body.style.overflow = 'auto';
        if (userDeviceOS() === 'Windows') {
          document.body.style.paddingRight = '0';
        }
      }
    }
  }, [isCartOpen]);

  const { cartItems } = useMemo(() => {
    const cartItems = getCartDisplayItems({
      promoCode:
        promoContent?.partnership_name || isRafGiftFlow
          ? (promoContent?.promo_info as PromoCode)
          : promoInfo?.promo_code,
      accessories: accessories ?? [],
      addToCart,
      currency,
      requiredProducts,
      updateProductQuantity,
      cartProducts,
      engravingUpsellAction,
      shouldSkipMemSelect,
      changeMembershipAction,
      learnMoreAction: () => setIsLearnMoreModalOpen(true),
      language,
      region,
    });

    return { cartItems };
  }, [cartProducts, currency, promoCode]);

  const totals = useMemo(() => {
    return getTotalsFromCartProducts({
      cartProducts,
      currency,
      initialTotals,
      promoCode,
      promoInfo,
    });
  }, [cartProducts, currency, initialTotals, promoCode, promoInfo]);

  const handleUpdateQuantity = (product: CartProduct, newQuantity: number) => {
    if (newQuantity === 0) {
      trackAmplitudeEvent(AmplitudeEvents.RemovedItemFromCart, {
        ...getItemProperties(product.item),
        page: pathname,
      });
    }
    updateProductQuantity(product, newQuantity);
  };

  const annualMembership = getMembership(
    'prepaid',
    requiredProducts.join_memberships,
    12,
  );
  const familyMemberships = requiredProducts.family_memberships?.length
    ? requiredProducts.family_memberships
    : null;

  const { displayCTACard, title, subtitle } = getPreferredFamilyPlanUpsellInfo({
    cartItems,
    annualMembership,
    familyMemberships,
    isRafGiftFlow,
    asAGift,
    currentPage,
    currency,
    language,
    region,
  });

  const handleFamilyCartCTAClick = () => {
    const label = AmplitudeProperties.FamilyCartUpsellClick;
    const page = currentPage;
    const prepaidMembershipInCart = cartItems.find((item) => {
      return item.item.item.membership_type === 'prepaid';
    });
    const membership_quantity = prepaidMembershipInCart?.item.quantity;
    trackAmplitudeEvent(AmplitudeEvents.ClickedFamilyMembership, {
      label,
      page,
      membership_quantity,
    });
    onCartClose();
    goTo('family');
  };

  return (
    <>
      <LearnMoreModal
        isOpen={isLearnMoreModalOpen}
        onChooseMembership={() => setIsCartOpen(true)}
        setIsOpen={setIsLearnMoreModalOpen}
      />
      <Drawer
        blurBackground={false}
        isOpen={isCartOpen}
        onClose={() => trackAmplitudeEvent(AmplitudeEvents.CloseCart)}
        position='right'
        setIsOpen={setIsCartOpen}
        title={t('yourCart')}
      >
        <div className='sm:max-w-[360px]'>
          <CartContents
            currency={currency}
            isLocked={false}
            items={cartItems}
            language={language}
            onItemQuantityChange={handleUpdateQuantity}
            promoInfo={promoInfo}
            region={region}
          />
          {displayCTACard ? (
            <div className='pb-3 pt-4'>
              <CTACard
                actionIconType='add'
                bgColor='bg-gray-50'
                className='border-none'
                iconSrc='/community-icon.svg'
                onClick={handleFamilyCartCTAClick}
                subtitles={[subtitle]}
                title={title}
              />
            </div>
          ) : null}
          <OrderTotals
            accessories={accessories ?? []}
            allTotalsCalculated={false}
            cartProducts={cartProducts}
            currency={currency}
            language={language}
            region={region}
            shippingAddress={shippingAddress}
            totals={totals}
          />
          {cartExceedsBatteryLimit ? (
            <div className='text-md text-red-a400 relative rounded-lg bg-gray-100 p-3 text-center'>
              {t('cartExceedsBatteryLimit')}
            </div>
          ) : null}
          {showBNPLMessaging ? (
            <>
              <hr className='border-gray-10 border-t pb-2' />
              <StripeBNPLElement
                cartVariant
                countryCode={country}
                currency={currency}
                language={language}
                price={getBNPLTotalToUse(totals)}
              />
            </>
          ) : null}
          <div className={clsx('m-auto w-72', showBNPLMessaging && 'pt-10')}>
            {showCartContinueButton ? (
              <CartCTAButtons
                onCartClose={onCartClose}
                variant={cartCTAButtonsVariant}
              />
            ) : undefined}
          </div>
          <div className='py-20 md:py-0'>
            {/* Spacer for viewing buttons on mobile */}
          </div>
        </div>
      </Drawer>
    </>
  );
}
